@import "./reset.css";

/** 
  Note that the value of the *-600 colors is currently equivalent to the value 
  of the *-500 variant of that color. This is a temporary change to make BW-42
  updates easier. 

  TODO remove comment when the color palette portion of BW-42 is completed.
*/

:root {
  --color-transparent-hover: rgb(0 0 0 / 0.03);

  --color-background: 255 255 255;
  --color-background-alt: 251 251 251;
  --color-background-alt2: 23 92 219;
  --color-background-alt3: 18 82 163;
  --color-background-alt4: 13 60 119;

  /* Can only be used behind the extension refresh flag */
  --color-primary-100: 200 217 249;
  --color-primary-300: 103 149 232;
  /* Can only be used behind the extension refresh flag */
  --color-primary-500: 23 93 220;
  --color-primary-600: 23 93 220;
  --color-primary-700: 18 82 163;

  --color-secondary-100: 240 240 240;
  --color-secondary-300: 206 212 220;
  --color-secondary-600: 137 146 159;
  --color-secondary-700: 33 37 41;

  --color-success-600: 1 126 69;
  --color-success-700: 0 85 46;

  --color-danger-600: 200 53 34;
  --color-danger-700: 152 41 27;

  --color-warning-600: 139 102 9;
  --color-warning-700: 105 77 5;

  --color-info-600: 85 85 85;
  --color-info-700: 59 58 58;

  --color-text-main: 33 37 41;
  --color-text-muted: 109 117 126;
  --color-text-contrast: 255 255 255;
  --color-text-alt2: 255 255 255;
  --color-text-code: 192 17 118;
  --color-text-headers: 2 15 102;

  --tw-ring-offset-color: #ffffff;
}

/*SLG Comment: tailwind light css style override start*/

.theme_light {
  --color-background-alt3: 255 255 255;
  --color-background-alt4: 220 220 220;

  --color-success-600: 93 168 95;

  --color-danger-600: 191 82 66;

  --color-primary-500: 250 175 66;
  --color-primary-600: 221 156 58;
  --color-primary-700: 221 156 58;

  --color-text-contrast: 255 255 255;
  --color-text-alt2: 51 50 46;

  --color-warning-500: 221 156 58;

  --tw-text-opacity: 0 !important;

  --slg-brand-primary: #faaf42;
  --slg-brand-secondary: #dd9c3a;
  --slg-black: #33322e;

  --slg-brand-primary: #faaf42;
  --slg-brand-secondary: #dd9c3a;
  --slg-white: #ffffff;
  --slg-accent-primary: #207292;
  --slg-accent-secondary: #e8292f;
  --slg-accent-third: #faaf42;
  --slg-accent-fourth: #403f34;
  --slg-accent-fifth: #403f34;
  --slg-notification-success: #2e7d32;
  --slg-icon-success: #2f8033;
  --slg-error: #d42b2b;
  --slg-hexagon-red-color: #e8292f;
  --slg-hexagon-brand-color: #e8292f;
  --slg-grey: #757574;
  --slg-light-grey: #ccc;
  --slg-dark-grey: #515151;
  --slg-black: #33322e;
  --slg-grey-background: #fafafa;
  --slg-cadet-blue: #227799;
  --slg-hex-border: #e8292f;

  --slg-background-primary: #f5f5f5;
  --slg-background-secondary: #f2f2f2;
  --slg-separator: #e6e5e3;
  --slg-inactive: #495057;
  --slg-font-primary: #403f34;
  --slg-font-secondary: #403f34;

  --slg-banner-green: #2f8033;
  --slg-banner-green-text: white;
  --slg-banner-yellow: #f3d351;
  --slg-banner-yellow-text: #403f34;
  --slg-banner-red: #d13636;
  --slg-banner-red-text: white;
  --slg-banner-pink: #ffe8e8;
  --slg-banner-pink-text: #605a5a;
}

/* button text */
html.theme_light button.\!tw-text-primary-500,
html.theme_light a.\!tw-text-primary-500,
html.theme_light button.\!tw-text-contrast,
html.theme_light .navbar-dark .navbar-nav .active > .nav-link,
html.theme_light .navbar-dark .navbar-nav .nav-link,
html.theme_light .btn-primary,
html.theme_light .btn-primary:hover:not(:disabled) {
  color: var(--slg-black) !important;
}

html.theme_light .navbar-dark .navbar-nav .nav-link:hover,
html.theme_light .navbar-dark .navbar-nav .nav-link:focus {
  color: var(--slg-grey) !important;
}

html.theme_light .btn-primary {
  background-color: var(--slg-brand-primary);
  border-color: var(--slg-brand-primary);
}

html.theme_light .btn-primary:hover:not(:disabled) {
  background-color: var(--slg-brand-secondary);
  border-color: var(--slg-brand-secondary);
}

html.theme_light aside.tw-bg-background-alt3 {
  border-right-color: rgb(var(--color-background-alt4));
  border-right-width: 1px;
  border-right-style: solid;
}
/*SLG Comment: tailwind light css style override end*/

.theme_dark {
  --color-transparent-hover: rgb(255 255 255 / 0.12);

  --color-background: 31 36 46;
  --color-background-alt: 22 28 38;
  --color-background-alt2: 47 52 61;
  --color-background-alt3: 47 52 61;
  --color-background-alt4: 16 18 21;

  --color-primary-100: 8 31 73;
  --color-primary-300: 23 93 220;
  --color-primary-500: 54 117 232;
  --color-primary-600: 106 153 240;
  --color-primary-700: 180 204 249;

  --color-secondary-100: 47 52 61;
  --color-secondary-300: 110 118 137;
  --color-secondary-600: 186 192 206;
  --color-secondary-700: 255 255 255;

  --color-success-600: 82 224 124;
  --color-success-700: 168 239 190;

  --color-danger-600: 255 141 133;
  --color-danger-700: 255 191 187;

  --color-warning-600: 255 235 102;
  --color-warning-700: 255 245 179;

  --color-info-600: 164 176 198;
  --color-info-700: 209 215 226;

  --color-text-main: 255 255 255;
  --color-text-muted: 186 192 206;
  --color-text-contrast: 25 30 38;
  --color-text-alt2: 255 255 255;
  --color-text-code: 240 141 199;
  --color-text-headers: 226 227 228;

  --tw-ring-offset-color: #1f242e;
}

.theme_nord {
  --color-transparent-hover: rgb(255 255 255 / 0.12);

  --color-background: 67 76 94;
  --color-background-alt: 59 66 82;
  --color-background-alt2: 76 86 106;
  --color-background-alt3: 76 86 106;
  --color-background-alt4: 67 76 94;

  --color-primary-300: 108 153 166;
  --color-primary-600: 136 192 208;
  --color-primary-700: 160 224 242;

  --color-secondary-100: 76 86 106;
  --color-secondary-300: 94 105 125;
  --color-secondary-600: 216 222 233;
  --color-secondary-700: 255 255 255;

  --color-success-600: 163 190 140;
  --color-success-700: 144 170 122;

  --color-danger-600: 228 129 139;
  --color-danger-700: 191 97 106;

  --color-warning-600: 235 203 139;
  --color-warning-700: 210 181 121;

  --color-info-600: 129 161 193;
  --color-info-700: 94 129 172;

  --color-text-main: 229 233 240;
  --color-text-muted: 216 222 233;
  --color-text-contrast: 46 52 64;
  --color-text-alt2: 255 255 255;
  --color-text-code: 219 177 211;

  --tw-ring-offset-color: #434c5e;
}

.theme_solarized {
  --color-transparent-hover: rgb(255 255 255 / 0.12);

  --color-background: 0 43 54;
  --color-background-alt: 7 54 66;
  --color-background-alt2: 31 72 87;
  --color-background-alt3: 31 72 87;
  --color-background-alt4: 0 43 54;

  --color-primary-300: 42 161 152;
  --color-primary-600: 133 153 0;
  --color-primary-700: 192 203 123;

  --color-secondary-100: 31 72 87;
  --color-secondary-300: 101 123 131;
  --color-secondary-600: 131 148 150;
  --color-secondary-700: 238 232 213;

  --color-success-600: 133 153 0;
  --color-success-700: 192 203 123;

  --color-danger-600: 220 50 47;
  --color-danger-700: 223 135 134;

  --color-warning-600: 181 137 0;
  --color-warning-700: 220 189 92;

  --color-info-600: 133 153 0;
  --color-info-700: 192 203 123;

  --color-text-main: 253 246 227;
  --color-text-muted: 147 161 161;
  --color-text-contrast: 0 0 0;
  --color-text-alt2: 255 255 255;
  --color-text-code: 240 141 199;

  --tw-ring-offset-color: #002b36;
}

@import "./popover/popover.component.css";
@import "./search/search.component.css";

@import "./toast/toast.tokens.css";
@import "./toast/toastr.css";

/** 
 * tw-break-words does not work with table cells:
 * https://github.com/tailwindlabs/tailwindcss/issues/835
 */
td.tw-break-words {
  overflow-wrap: anywhere;
}

/** 
 * tw-list-none hides summary arrow in Firefox & Chrome but not Safari:
 * https://github.com/tailwindlabs/tailwindcss/issues/924#issuecomment-915509785
 */
summary.tw-list-none::marker,
summary.tw-list-none::-webkit-details-marker {
  display: none;
}

/** 
 * Arbitrary values can't be used with `text-align`:
 * https://github.com/tailwindlabs/tailwindcss/issues/802#issuecomment-849013311
 */
.tw-text-unset {
  text-align: unset;
}

/**
 * Bootstrap uses z-index: 1050 for modals, dialogs should appear above them.
 * Remove once bootstrap is removed from our codebase.
 * CL-XYZ
 */
.cdk-overlay-container,
.cdk-global-overlay-wrapper,
.cdk-overlay-connected-position-bounding-box,
.cdk-overlay-backdrop,
.cdk-overlay-pane {
  z-index: 2000 !important;
}

.cdk-global-scrollblock {
  position: relative;
  height: 100%;
  overflow: hidden;
}
